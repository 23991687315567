import Vue from 'vue'
import home from './home.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/css/app.scss";

Vue.use(ElementUI);


Vue.config.productionTip = false

new Vue({
  render: h => h(home),
}).$mount('#app')
