<template>
<div class="app-container">
    <top-nav useIn="home"></top-nav>
    <div id="carousel">
        <el-carousel :height="carouselHeight" :interval="3000" indicator-position="none">
            <el-carousel-item v-for="(src,idx) in imgSrcArr" :key="idx">
                <el-image style="width: 100%;height:100%;" fit="cover" :src="src" @click="onClickImage(src)" />
            </el-carousel-item>
        </el-carousel>
    </div>
    <div class="beian">
        <a target="_blank" href="https://beian.miit.gov.cn" style="margin: 0px 10px 0px 0px;line-height:20px;color:#939393;">黔ICP备2021008426号-1</a>
        <div style="width:300px;margin:0 auto; padding:20px 0;">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52011502001687" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <img src="https://lqhb-1301820126.cos.ap-chengdu.myqcloud.com/20220112/e1311f816f294c308393aadb73ebda5a.png" style="float:left;" />
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">贵公网安备 52011502001687号</p>
            </a>
        </div>
    </div>
</div>
</template>

<script>
import TopNav from "@/components/top-nav.vue"
const hbjqImgSrc = "https://lqhb-1301820126.cos.ap-chengdu.myqcloud.com/20211126/8462069640ca4a2da20f70135062204c.png";
const lqImgSrc = "https://lqhb-1301820126.cos.ap-chengdu.myqcloud.com/20211126/7daaf24b49724e899694004eaee8d5bd.png";
import {
    contentHeight
} from "@/css/size.js";

export default {
    components: {
        TopNav
    },
    data() {
        return {
            imgSrcArr: [hbjqImgSrc, lqImgSrc, hbjqImgSrc, lqImgSrc],
            carouselHeight: 0
        }
    },
    mounted() {
        this.carouselHeight = contentHeight();
    },
    methods: {
        onClickImage(src) {
            if (src == hbjqImgSrc) {
                window.open("https://lqhb.com.cn/admin/")
            } else {
                location.href = "./intro.html";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#carousel {
    cursor: pointer
}

.beian {
    position: absolute;
    bottom: 30px;
    left: 40%;
    color: white;
    text-decoration: none;
    z-index: 3000;
    display: flex;
    align-items: center;
}
</style>
